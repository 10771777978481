import PresentationHeaderSocial from "./PresentationHeaderSocial";

const Styles = {
  parent: {
    display: "block",
  },
  backgroundImage: {
    display: "inline-block",
    width: "100%",
  },
  userImage: {
    position: "relative",
    width: "25%",
    borderRadius: "50%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-15%",
    border: "5px solid #FFFFFF",
  },
  title: {
    textAlign: "center",
    marginTop: "12px",
    fontWeight: 500,
    fontSize: "24px",
  },
  description: {
    marginTop: "24px",
    marginLeft: "48px",
    marginRight: "48px",
    textAlign: "center",
  },
};

export default function PersonHeaderPresentation() {
  return (
    <div style={Styles.parent}>
      <img
        style={Styles.backgroundImage}
        src={require("../../assets/presentation_background.jpeg")}
        alt=""
      />
      <img
        style={Styles.userImage}
        src={require("../../assets/person.png")}
        alt=""
      />
      <p className="global-font" style={Styles.title}>
        Gabriel Brasileiro
      </p>
      <p className="global-font" style={Styles.description}>
        Atualmente trabalhando com a parte de desenvolvimento de aplicativos
        nativos para Android e iOS (Kotlin, Java e Swift), e na ministração de
        palestras e workshops.
        <br />
        <br />
        Dono do canal e site Universo do Android. A princípio, criado para
        ajudar os usuários a desfrutarem do máximo da performance de seus
        dispositivos Android.
        <br />
        <br />
        Possuo conhecimento em Web Design, adquirido durante o curso técnico que
        completei nos três anos de ensino médio, além de várias certificações em
        Inglês e nas áreas de programação Mobile
        <br />
        <br />
        Atualmente apaixonado por investimentos e estudando cada vez mais sobre
        produtos e suas aplicações.
      </p>
      <div style={Styles.buttonsCotainer}>
        <PresentationHeaderSocial />
      </div>
    </div>
  );
}
