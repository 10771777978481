import { AccordionDetails, Divider } from "@mui/material";
import { Accordion, AccordionSummary } from "../ext/AccordionExtensions";
import { AccordionItem } from "../cards/AccordionItem";

const Style = {
  divider: {
    marginTop: "12px",
    marginBottom: "12px",
  },
};

export function CurriculumAwards() {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
        <div sx={{ width: "33%", flexShrink: 0 }}>Reconhecimentos</div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <AccordionItem
            image={require("../../assets/data4good.png")}
            title="Data4Good"
            subtitle="Concedido por Campus Party"
            period="Abril de 2018"
            extras={
              <div>
                <p>
                  3º Colocado no Hackaton Data4Good que ocorreu na Campus Party
                  Natal.
                  <br /> · Dados Abertos
                  <br /> · Machine Learning
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../assets/unit.png")}
            title="Workshop - Java e Firebase"
            subtitle="Concedido(s) por UNIT"
            period="Maio de 2017"
            extras={
              <div>
                <p style={Style.extras}>
                  Workshop com intuito de ensinar aos alunos como utilizar
                  aplicações Android com o Firebase.
                </p>
              </div>
            }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
