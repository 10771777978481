const Style = {
  container: {
    display: "flex",
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    objectFit: "cover",
  },
  content: {
    flex: 1,
    marginLeft: "8px",
  },
  date: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#9ca3af",
  },
};

export function AccordionItem(props) {
  const image = props.image;
  const title = props.title;
  const subtitle = props.subtitle;
  const period = props.period;
  const extras = props.extras;

  return (
    <div style={Style.container}>
      <div>
        <img style={Style.image} src={image} alt="" />
      </div>
      <div
        style={Style.content}
        className="global-font-div global-font-spaccing"
      >
        <strong>{title}</strong>
        <p>{subtitle}</p>
        <p style={Style.date}>{period}</p>
        <div>{extras}</div>
      </div>
    </div>
  );
}
