import * as React from "react";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeTitle } from "../state/titleSlicer";
import { presentationTitle } from "./TitlePages";
import PersonHeaderPresentation from "../header/PersonHeaderPresentation";
import { CurriculumTimeline } from "../curriculum/CurriculumTimeline";
import { CurriculumDetails } from "../curriculum/CurriculumDetails";

export default function PresentationPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(presentationTitle));
  });

  return (
    <div>
      <PersonHeaderPresentation />
      <CurriculumTimeline />
      <CurriculumDetails />
    </div>
  );
}
