import { Card } from "@mui/material";
import { CurriculumEducation } from "./CurriculumEducation";
import { CurriculumCertifies } from "./CurriculumCertifies";
import { CurriculumVoluntary } from "./CurriculumVoluntary";
import { CurriculumAwards } from "./CurriculumAwards";

const Style = {
  card: {
    marginBottom: "64px",
    marginRight: "6%",
    marginLeft: "6%",
  },
};

export function CurriculumDetails() {
  return (
    <div className="global-font-div global-font-spaccing">
      <Card
        variant="outlined"
        style={Style.card}
        sx={{
          borderRadius: "8px",
        }}
      >
        <div>
          <CurriculumVoluntary />
          <CurriculumEducation />
          <CurriculumCertifies />
          <CurriculumAwards />
        </div>
      </Card>
    </div>
  );
}
