import { AccordionDetails, Button, Divider } from "@mui/material";
import { Accordion, AccordionSummary } from "../ext/AccordionExtensions";
import { AccordionItem } from "../cards/AccordionItem";
import { ButtonStyle } from "../../theme/ButtonStyles";
import { openNewTab } from "../ext/WindowExtensions";

const Style = {
  divider: {
    marginTop: "12px",
    marginBottom: "12px",
  },
  extras: {
    marginTop: "8px",
    marginBottom: "8px",
  },
  extrasEnd: {
    marginTop: "8px",
  },
};

export function CurriculumVoluntary() {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
        <div sx={{ width: "33%", flexShrink: 0 }}>Trabalho voluntário</div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <AccordionItem
            image={require("../../assets/toastmasters.png")}
            title="Toastmasters International"
            subtitle="Vice President of Education"
            period="Jul de 2024 – até o momento"
            extras={
              <div>
                <p style={Style.extras}>
                  Como Vice-Presidente de Educação do Toastmasters, eu trabalho
                  no crescimento e desenvolvimento do nosso clube, facilitando e
                  auxiliando na melhoria das habilidades de oratória e liderança
                  dos membros. Organizo e supervisiono as reuniões, garantindo
                  que os membros tenham oportunidades regulares para praticar e
                  receber feedback construtivo para o desenvolvimento de seus
                  discursos. Colaboro com outros dirigentes do clube para manter
                  um ambiente de aprendizagem acolhedor, personalizando os
                  caminhos educacionais de acordo com os objetivos de cada
                  membro.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../assets/gdg.png")}
            title="GDG Maceió"
            subtitle="Organizador"
            period="Abr de 2019 – Mar de 2023 · 4 anos"
            extras={
              <div>
                <p style={Style.extras}>
                  Como organizador do Google Developers Group (GDG), liderei uma
                  comunidade local de desenvolvedores apaixonados por aprender e
                  compartilhar conhecimentos sobre tecnologias do Google e
                  outras inovações na área de tecnologia. Meu papel envolve
                  criar uma comunidade colaborativa e inclusiva, oferecendo
                  suporte e criando oportunidades de networking e aprendizado
                  para desenvolvedores de todos os níveis de experiência. Em
                  2017, organizamos o maior Dev Fest do Brasil, com mais de 1000
                  participantes e mais de 25 palestrantes.
                </p>
                <Button
                  style={Style.extrasEnd}
                  sx={ButtonStyle}
                  variant="outlined"
                  onClick={() => {
                    openNewTab("https://gdg.community.dev/gdg-maceio/");
                  }}
                >
                  GDG Community
                </Button>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../assets/gdg.png")}
            title="GDG Maceió"
            subtitle="Facilitador"
            period="Dez de 2017 – Mar de 2019 · 1 ano e 4 meses"
            extras={
              <div>
                <p style={Style.extras}>
                  Como facilitador no GDG (Google Developers Group), eu
                  organizava eventos e atividades para reunir desenvolvedores
                  interessados em tecnologias Google. Criava oportunidades de
                  aprendizado, networking e troca de conhecimentos, promovendo
                  um ambiente inclusivo e colaborativo. Além disso, apoiava a
                  comunidade local, coordenando palestras, workshops e outras
                  iniciativas para ajudar os participantes a aprimorarem suas
                  habilidades técnicas e profissionais.
                </p>
                <Button
                  style={Style.extrasEnd}
                  sx={ButtonStyle}
                  variant="outlined"
                  onClick={() => {
                    openNewTab("https://gdg.community.dev/gdg-maceio/");
                  }}
                >
                  GDG Community
                </Button>
              </div>
            }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
