import { AccordionDetails, Divider } from "@mui/material";
import { AccordionItem } from "../cards/AccordionItem";
import { Accordion, AccordionSummary } from "../ext/AccordionExtensions";

const Style = {
  divider: {
    marginTop: "12px",
    marginBottom: "12px",
  },
};

export function CurriculumEducation() {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
        <div sx={{ width: "33%", flexShrink: 0 }}>Formação</div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <AccordionItem
            image={require("../../assets/estacio.png")}
            title="Estácio"
            subtitle="Bacharelado, Sistemas de Informação"
            period="2020 – 2023"
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../assets/ifal.png")}
            title="Instituto Federal de Alagoas"
            subtitle="Bacharelado, Sistemas de Informação"
            period="Ago de 2017 – Set de 2019"
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../assets/unit.png")}
            title="Universidade Tiradentes-Unit"
            subtitle="Bacharelado, Ciência da Computação"
            period="Jan de 2017 – Ago de 2017"
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../assets/senai.png")}
            title="Senai"
            subtitle="Web Designer, Back-end e Front-end"
            period="2014 – 2016"
            description="Atividades e grupos: Desenvolvimento do meu canal e site Universo do Android. Foco maior em Back-end."
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
